import './input.css';
import htmx from 'htmx.org';
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();

// Function to add or remove "scrolled" class based on scroll position
function toggleScrolledClass() {
    if (window.scrollY > 0) {
        document.body.classList.add('scrolled');
    } else {
        document.body.classList.remove('scrolled');
    }
}
// Event listener for scroll event
window.addEventListener('scroll', toggleScrolledClass);
